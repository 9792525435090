<template>
  <div>
    <div class="mb-3 d-flex flex-row-reverse">
      <div v-b-toggle="id" size="sm" @click="toggleExpansion">
        <span class="d-flex justify-center align-center">
          <b-icon class="expander" :icon="iconText" />
          <p class="ml-2">
            {{ iconSymbol }}
          </p>
        </span>
      </div>
    </div>

    <b-collapse :id="id">
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

const expanded = ref(false)

const iconSymbol = computed(() => expanded.value ? "Esconder tabela" : "Mostrar Tabela")
const iconText = computed(() => expanded.value ? "arrow-up-circle" : "arrow-down-circle")

function toggleExpansion() {
  expanded.value = !expanded.value
}
</script>

<style lang="scss">
.expander {
  width: 20px;
  height: 20px;
  fill: #ccc;
  outline: none !important;

  &:hover {
    fill: #335080;
    cursor: pointer;
  }
}
</style>
