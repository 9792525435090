<template>
  <b-table
    :items="state.data"
    :fields="columns"
    thead-tr-class="font-weight-normal"
    tbody-tr-class="text-secondary"
    show-empty
  >
    <template #head(actions)>
      <b-icon icon="filter-right" class="icon-size" />
    </template>
    <template #cell(actions)="{ item }">
      <cell-actions
        :item="item"
        view_to="show_action_plan"
        edit_to="show_action_plan"
        :view_edit="false"
        @delete="deleteItem"
      />
    </template>
    <template #empty>
      <div class="column text-center mb-4 mt-5">
        <b-icon icon="exclamation-lg" font-scale="2" />
        <div class="h6 font-weight-medium mt-2">Não há planos cadastrados</div>
        <p class="text-muted">Adicione novos planos de ações</p>
        <b-button variant="primary" pill size="sm" @click="$emit('add-action')">
          <b-icon icon="plus" />
          Adicionar plano de ação
        </b-button>
      </div>
    </template>
  </b-table>
</template>
<script setup>
import { onMounted, reactive } from "vue";

import CellActions from "@/components/Table/Actions.vue";
import { useRoute } from "@/composables/useRoute";
import { useMapActions } from "@/composables/useMapActions";
import { useNotification } from "@/composables/useNotification";

const state = reactive({
  data: [],
});
const emits = defineEmits(["add-action"]);
const route = useRoute();
const $notify = useNotification();
const columns = [
  {
    key: "name",
    label: "Título",
    class: "col-4",
  },
  {
    key: "length_actions",
    label: "Ações",
    class: "text-right col-1",
  },
  {
    key: "date_end",
    label: "Data final",
    class: "text-center col-1",
  },
  {
    key: "who",
    label: "Responsável",
    class: "col-3",
  },
  {
    key: "actions",
    label: "",
    class: "col-1 text-center",
  },
];

const { ActionGetAll, ActionDeleteActionPlan } = useMapActions("action_plans", [
  "ActionGetAll",
  "ActionDeleteActionPlan",
]);

async function loadData() {
  try {
    const parameters = { id: route.params.id };
    const result = await ActionGetAll(parameters);

    if (!Array.isArray(result.data)) {
      return;
    }

    state.data = result.data.map((item) => {
      item.length_actions = (item.action_plans_items || []).length;
      return item;
    });
  } catch (error) {}
}

async function deleteItem(item) {
  try {
    const result = await ActionDeleteActionPlan(item);
    const message = result?.message || "Ação removida com sucesso";

    $notify.addNotification({ message, type: "success" });
  } catch (error) {
    $notify.addNotification({
      message: "Não foi possível remover a ação",
      type: "danger",
    });
  } finally {
    await loadData();
  }
}

onMounted(() => {
  loadData();
});
</script>
<style scoped lang="scss">
::v-deep .font-weight-normal th {
  border-top: 0 !important;
  font-weight: normal !important;
}

.icon-size {
  width: 28px;
  height: 28px;
}
</style>
