import { useStore } from "@/composables/useStore";
import {
  isValidMap,
  normalizeMap,
  normalizeNamespace,
  getModuleByNamespace,
} from "@/helpers/functions/normalizeMap";

/**
 * Utility for map vuex actions
 * @example
 * const { baz } = useMapActions("foo", { baz: "bar" })
 *
 * baz()
 *
 * @param {string} storeName
 * @param {{} | string[]} map
 * @returns {{ [key: string]: (args: unknown) => Promise<unknown>}}
 */
export function useMapActions(storeName, map) {
  const $store = useStore();
  const actions = {};
  normalizeNamespace((storePath, map) => {
    if (!isValidMap(map)) {
      console.error(
        "[vuex] mapActions: mapper parameter must be either an Array or an Object"
      );
      return actions;
    }

    for (const ref of normalizeMap(map)) {
      const module = getModuleByNamespace($store, storePath);

      actions[ref.key] = function mappedAction(...args) {
        let dispatch = $store.dispatch;
        if (storePath) {
          if (!module) {
            return;
          }
          dispatch = module.context.dispatch;
        }
        return typeof ref.value === "function"
          ? ref.value.apply(this, [dispatch].concat(args))
          : dispatch.apply($store, [ref.value].concat(args));
      };
    }
  })(storeName, map);
  return actions;
}
