<template>
  <b-card
    v-if="objective"
    class="rounded-top border-0 rounded-bottom-0"
    header-class="bg-white border-0 pt-4"
    body-class="pt-0"
  >
    <template #header>
      <b-row no-gutters align-v="center">
        <b-col v-if="showBackButton" cols="12" class="mb-3">
          <b-button
            pill
            variant="outline-primary"
            :to="backLink"
            v-b-tooltip.hover
            title="Voltar para a listagem de objetivos"
          >
            <b-icon icon="arrow-left" />
            Voltar
          </b-button>
        </b-col>
        <b-col class="ods-images" v-if="hasOds">
          <b-row align-v="end" no-gutters>
            <b-col
              v-for="(ods, key) in objective.ods_models"
              :key="key"
              sm="auto"
              v-b-tooltip.hover
              :title="ods.name"
            >
              <b-img
                :src="'https://odsbrasil.gov.br/content/ods/' + ods.id + '.png'"
                rounded
                :alt="ods.name"
              ></b-img>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <b-button
            :to="linkObjectives"
            variant="primary"
            pill
            v-b-tooltip.hover
            class="text-uppercase"
            :title="linkDescription"
          >
            <b-icon :icon="linkIcon" class="mr-1" />
            {{ linkLabel }}
          </b-button>
        </b-col>
        <b-col cols="12">
          <card-header
            :caption-top="captionTop"
            :label="objective.name"
            :icon="plan_icon"
            class="pt-2 border-0"
            label-class="h5"
            :show-status="false"
          />
        </b-col>
      </b-row>
    </template>
    <b-card-text class="text-muted text-body1">
      {{ description }}
      <span
        class="text-primary cursor-pointer"
        @click="toggleText()"
        v-if="isEmptyDescription"
      >
        {{ maxLengthLabel }}
      </span>
    </b-card-text>
    <div class="pt-4 pb-4">
      <b-row>
        <b-col
          cols="6"
          md="4"
          v-for="(plan_struture, key) in objective.structure"
          :key="key"
        >
          <card-label
            class="h-100"
            :label="levels[key]"
            :description="plan_struture.name"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script setup>
import _ from "lodash";
import { ref, computed } from "vue";

import CardHeader from "@/components/Home/Card/CardHeader.vue";
import $formatDate from "@/helpers/filters/format_date";
import CardLabel from "./CardLabel.vue";

import { useRoute } from "@/composables/useRoute";
import { useStore } from "@/composables/useStore";

const maxLength = ref(115);
const $store = useStore();
const $route = useRoute();

const objective = computed(() => $store.state.objectives.objective);

const levels = computed(() => {
  if (
    objective.value.plan_config &&
    objective.value.plan_config.plan_structure &&
    Array.isArray(objective.value.plan_config.plan_structure.levels)
  ) {
    return objective.value.plan_config.plan_structure.levels;
  }
  return [];
});

const isEmptyDescription = computed(
  () => objective.value.description?.length > 3
);

const description = computed(() => {
  const splitedDescription = (objective.value?.description || "").substring(
    0,
    maxLength.value
  );
  const forEmptyText = "Visualize as metas deste objetivo logo a baixo";
  return isEmptyDescription.value ? `${splitedDescription}` : forEmptyText;
});

const maxLengthLabel = computed(() => {
  return maxLength.value ? "...Ver mais" : "...Ver menos";
});

const plan_icon = computed(() => {
  if (objective.value.strategic_plan.icon) {
    const icon = objective.value.strategic_plan.icon.split(" ");
    if (icon.length) {
      return icon;
    }
  }
  return "";
});

const captionTop = computed(() => {
  const date = objective.value.updated_at;
  const formatedDate = $formatDate(date);
  const planName = objective.value.strategic_plan.name;
  return `${planName} · Última atualização em ${formatedDate}`;
});

const nextRouteIsEdit = computed(() => $route.name !== "objectives_edit");

const showBackButton = computed(() => nextRouteIsEdit.value);
const backLink = computed(() => ({
  name: "strategic_plans_show",
  params: { id: objective.value?.plan_config?.strategic_plan_id },
}));

const linkObjectives = computed(() => {
  const id = objective.value.id;
  if (nextRouteIsEdit.value) {
    return {
      name: "objectives_edit",
      params: { id },
    };
  }
  return {
    name: "objectives_show",
    params: { id },
  };
});

const linkLabel = computed(() => (nextRouteIsEdit.value ? "Editar" : "Voltar"));

const linkDescription = computed(() =>
  nextRouteIsEdit.value
    ? "Edite as definições deste objetivo"
    : "Voltar para a exibição deste objetivo"
);

const linkIcon = computed(() =>
  nextRouteIsEdit.value ? "pencil-square" : "arrow-left"
);
const hasOds = computed(
  () =>
    _.isArray(objective.value.ods_models) &&
    !_.isEmpty(objective.value.ods_models)
);

function toggleText() {
  maxLength.value = maxLength.value === 115 ? undefined : 115;
}
</script>

<style lang="scss">
.ods-images {
  text-align: right;
  img {
    max-width: 38px;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
