<template>
  <div>
    <Echarts :options="options" ref="bar" theme="ovilia-green" autoresize />
  </div>
</template>

<script>
import Echarts from "./Echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/geo";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataset";
import "echarts/lib/component/grid";

export default {
  components: { Echarts },
  props: {
    legend: {
      type: Object,
      default: () => ({}),
    },
    tooltip: {
      type: Object,
      default: () => ({}),
    },
    xAxis: {
      type: Object,
      default: () => ({ type: "category" }),
    },
    yAxis: {
      type: Object,
      default: () => ({ type: "value" }),
    },
    series: {
      type: Array,
      required: true,
    },
  },
  computed: {
    options() {
      return {
        legend: this.legend,
        tooltip: this.tooltip,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      };
    },
  },
};
</script>

<style></style>
