<template>
  <div>
    <template v-if="hasListIndicators">
      <b-card
        v-for="(indicator, index) in state.listIndicators"
        :key="index"
        :header="indicator.name"
        header-tag="h3"
        class="m-4"
      >
        <p>{{ indicator.description }}</p>

        <div height="300">
          <measure-target :measure="indicator" />
        </div>
      </b-card>
    </template>

    <b-card class="border-0 text-center py-5" v-else>
      <b-card-text>
        <b-icon icon="exclamation-circle" class="h3" variant="warning" />
        <p class="text-muted">Ainda não há indicadores cadastrados</p>
      </b-card-text>
    </b-card>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="no-more" class="my-4 text-info">
        <b-icon icon="check-circle" />
        Isso é tudo
      </div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script setup>
import { computed, reactive } from "vue";
import InfiniteLoading from "vue-infinite-loading";

import { useStore } from "@/composables/useStore";
import MeasureTarget from "@/modules/objectives/components/MeasureTarget.vue";

const props = defineProps({
  objectiveId: {
    type: [String, Number],
    default: "",
  },
});

const state = reactive({
  listIndicators: [],
  limit: 10,
  page: 1,
  total: 0,
  lastPage: 0,
  loading: false,
  firstLoad: true,
});

const $store = useStore();

const hasListIndicators = computed(() =>
  state.loading ? (state.listIndicators || []).length > 0 : true
);

async function loadData() {
  try {
    const payload = {
      objective_id: props.objectiveId,
      limit: state.limit,
      page: state.page,
      classification: "indicator",
    };

    const result = await $store.dispatch(
      "objectives/ActionGetMeasures",
      payload
    );

    state.listIndicators.push(...result.data);
    state.page = result.meta.current_page + 1;
    state.lastPage = result.meta.last_page;
    state.total = result.meta.total;
  } catch (error) {}
}

async function infiniteHandler($state) {

  if(state.firstLoad) {
    state.loading = true;
    await loadData();
    state.loading = false;
    state.firstLoad = false;
    $state.loaded();
    return;
  }

  if (!state.loading) {
    state.loading = true;

    if (state.lastPage < state.page) {
      $state.complete();
      return;
    }
    try {
      await loadData();
    } finally {
      $state.loaded();
    }
  }
  state.loading = false;
}


</script>
<style></style>
