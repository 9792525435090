<template>
  <b-card :header-class="headerClass" body-class="pt-0" :bg-variant="bgVariant">
    <template #header>
      <card-header :label="label" :label-class="labelClass" :show-status="false" />
    </template>
    <b-card-text class="text-muted text-body1">{{ description }}</b-card-text>
  </b-card>
</template>
<script setup>
import { computed } from 'vue'

import CardHeader from "@/components/Home/Card/CardHeader.vue";

const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  description: {
    type: String,
    default: ""
  },
  dark: {
    type: Boolean,
    default: false
  },
  bgVariant: {
    type: String,
    default: ""
  }
})

const isDarkVariant = computed(() => props.bgVariant.includes("dark"))

const headerClass = computed(() => {
  const darkClass = isDarkVariant.value ? "" : "bg-white";
  return `pb-1 border-0 ${darkClass}`;
})

const labelClass = computed(() => {
  const darkClass = isDarkVariant.value ? "text-white" : "";
  return `text-body1 ${darkClass}`;
})

</script>
