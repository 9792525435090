<template>
  <div v-if="state.chart_data">
    <div class="bar-chart">
      <Bar :series="series" :xAxis="xAxis" />
      <div v-if="measure.source" class="w-100 text-muted">
        <i>Fonte: {{ measure.source }}</i>
      </div>
    </div>

    <collapse-button :id="'tabelaMetaId_' + measure.id">
      <div class="measures-table">
        <b-table striped hover :items="items_table_values" :fields="fields">
          <template
            v-for="(index, key) in state.measure_keys"
            v-slot:[`head(${index.key})`]="{}"
          >
            <span :key="key"
              >{{ index.label }}
              <font-awesome-icon
                :icon="['fas', 'circle']"
                :style="{ color: index.raw.color }"
              />
            </span>
          </template>

          <template #cell(value)="row">
            <span>
              <font-awesome-icon
                v-if="row.item.value"
                :icon="['fas', 'circle']"
                :style="{ color: get_color(row.item) }"
              />
              {{ row.item.value | number_format(format_values) }}
            </span>
          </template>

          <template
            v-for="index in measure_keys"
            v-slot:[`cell(${index.key})`]="{ item }"
            >{{ item[index.key] | number_format(format_values) }}
          </template>

          <template
            v-for="(index, key) in state.dynamic_fields"
            v-slot:[`cell(${index})`]="row"
          >
            <span :key="key">
              <span v-if="row.item[index]">
                <font-awesome-icon
                  v-if="row.item[index].value"
                  :icon="['fas', 'circle']"
                  :style="{ color: get_color(row.item[index]) }"
                />
                {{ row.item[index].value | number_format(format_values) }}
              </span>

              <span v-else-if="row.item['raw']">
                {{ get_measure_value(row.item, row.field.label) }}
              </span>
            </span>
          </template>
        </b-table>
      </div>
    </collapse-button>
  </div>
</template>

<script setup>
import _ from "lodash";
import { onMounted, reactive, computed } from "vue";

import CollapseButton from "./CollapseButton.vue";
import Bar from "@/components/Echarts/Bar.vue";
import $number_format from "@/helpers/filters/number_format";
import { useStore } from "@/composables/useStore";
import { useConfig } from "@/composables/useConfig";

const props = defineProps({
  measure: {
    type: Object,
    required: true,
  },
});

const $store = useStore();
const $config = useConfig();

const state = reactive({
  hide: false,
  chart_data: null,
  dynamic_fields: [],
});

const fields = computed(() => {
  let fields = [];

  if (props.measure.chart_config.show_table_frequency === "colunas") {
    fields = [{ key: "label", label: "#" }];
    const values = table_values.value;

    values.forEach((value, key) => {
      fields.push({ key: "date_" + key, label: value.date });
    });
  } else {
    fields = [
      { key: "date", label: "#" },
      { key: "value", label: props.measure.metric.label },
    ];

    if (!props.measure.chart_config.show_table_legend) {
      fields = _.concat(fields, measure_keys.value);
    }
  }

  return fields;
});

const measure_keys = computed(() => {
  const measures = state.chart_data.measures.slice();

  return measures.map((values) => ({
    key: values.label,
    label: formatLabelMeasure(values),
    raw: values,
  }));
});

const table_values = computed(() => {
  const values = JSON.parse(JSON.stringify(chart_values.value));
  //return values.reverse();
  return values;
});

const items_table_values = computed(() => {
  if (props.measure.chart_config.show_table_frequency === "colunas") {
    let items = [];
    const values = table_values.value;
    const item_table = {
      label: props.measure.metric.label,
    };

    values.forEach((value, key) => {
      item_table["date_" + key] = value;
      item_table["date_" + key].key = "date_" + key;
    });

    items.push(item_table);

    if (!props.measure.chart_config.show_table_legend) {
      items = _.concat(items, measure_keys.value);
    }

    return items;
  }
  return table_values.value;
});

const xAxis = computed(() => {
  const data = _.map(chart_values.value, "date");
  return {
    type: "category",
    data: data,
  };
});

const series = computed(() => {
  const data = _.map(chart_values.value, (obj) => {
    let value = obj.value;
    const color = get_color(obj);

    return color == "transparent"
      ? value
      : {
          value,
          itemStyle: {
            color: color,
          },
        };
  });

  const xAxisData = xAxis.value.data;
  const measures = _.map(state.chart_data.measures, (obj) => {
    const data_value = _.map(xAxisData, (x) => {
      const value = _.find(obj.values, ["date", x]);
      return value ? value.value : null;
    });

    return {
      name: formatLabelMeasure(obj),
      type: "line",
      itemStyle: { color: obj.color },
      emphasis: {
        focus: "series",
      },
      connectNulls: true,
      data: data_value,
    };
  });

  if (props.measure.chart_config.limit_hide_last) {
    measures.pop();
  }

  const formatValueNumber = (value) => {
    if (typeof value.data === "number") {
      return $number_format(value.data, format_values.value);
    }
    return $number_format(value.data.value);
  };

  const other_values_colors = [
    '#5499C7',
    '#7FB3D5',
    '#A9CCE3',
    '#D4E6F1',
    '#D7DBDD',
    '#E5E7E9'
  ];
  const other_values = [];
  if (state.chart_data.other_values) {
    state.chart_data.other_values.forEach((other_value, key) => {
      const data = _.map(chart_values.value, other_value.label);

      other_values.push({
        name: other_value.label,
        data: data,
        type: "bar",
        label: {
          show: !!props.measure.chart_config.label_show,
          position: props.measure.chart_config.label_position,
          formatter: formatValueNumber,
        },
        itemStyle: {
            color: other_values_colors[key],
          },
      });
    });
  }

  return [
    {
      name: state.chart_data.label,
      data: data,
      type: "bar",
      label: {
        show: !!props.measure.chart_config.label_show,
        position: props.measure.chart_config.label_position,
        formatter: formatValueNumber,
      },
    },
    ...other_values,
    ...measures,
  ];
});

const chart_values = computed(() => {
  const chartValues = (state.chart_data?.values || []).slice();
  if (!state.chart_data && !Array.isArray(state.chart_data?.measures)) {
    return [];
  }

  state.chart_data.measures.forEach((measures_data) => {
    measures_data.values.forEach((values) => {
      const index = _.findIndex(chartValues, { date: values.date });
      if (index >= 0) {
        chartValues[index][measures_data.label] = values.value;
      } else {
        const new_value = {
          date: values.date,
          [measures_data.label]: values.value,
        };
        chartValues.push(new_value);
      }
    });
  });

  if (state.chart_data.other_values) {
    state.chart_data.other_values.forEach((other_value) => {
      other_value.values.forEach((values) => {
        const index = _.findIndex(chartValues, { date: values.date });
        if (index >= 0) {
          chartValues[index][other_value.label] = values.value;
        }
      });
    });
  }

  return chartValues;
});

const format_values = computed(() => {
  let format = "0,000";
  const decimals = state.chart_data.decimals;
  if (decimals > 0) {
    format += "." + "".padEnd(decimals, "0");
  }
  return format;
});

async function loadChartData() {
  const payload = {
    id: props.measure.id,
    ibge: $config.ibge,
  };

  state.chart_data = await $store.dispatch("measures/ActionChartData", payload);

  const valuesLength = (table_values.value || []).length;
  let index = 0;
  while (index < valuesLength) {
    state.dynamic_fields.push("date_" + index);
    index += 1;
  }
}

function performOperation(operator, values = []) {
  const [firstValue, secondValue] = values;
  switch (operator) {
    case "==":
      return firstValue == secondValue;
    case ">":
      return firstValue > secondValue;
    case ">=":
      return firstValue >= secondValue;
    case "<":
      return firstValue < secondValue;
    case "<=":
      return firstValue <= secondValue;
  }
}

function get_color(item) {
  const items = (state.chart_data.measures || []).slice();
  if (item && item.value !== undefined) {
    for (const measure of items) {
      if (item[measure.label] !== undefined) {
        const values = [item.value, item[measure.label]].map(Number);
        const result = performOperation(measure.operator, values);
        const isBiggerThanOperator = measure.operator == ">";
        const isGreaterThanOrEqual = measure.operator == ">=";

        if (result /* && !isBiggerThanOperator && !isGreaterThanOrEqual */) {
          return measure.color;
        }
      }
    }
  }

  return "transparent";
}

function formatLabelMeasure(values) {
  const OPERATORS = {
    "==": "=",
    ">": ">",
    ">=": "≥",
    "<": "<",
    "<=": "≤",
  };
  const formatedOperator = OPERATORS[values.operator];
  return formatedOperator
    ? `${values.label} (${formatedOperator})`
    : values.label;
}

function get_measure_value(item, index) {
  const values = _.find(item["raw"]["values"], { date: index });
  return values ? values.value : null;
}

onMounted(() => {
  loadChartData();
});
</script>

<style scoped>
name .bar-chart {
  position: relative;
  margin: auto;
  width: 100%;
}
</style>
