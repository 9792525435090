import { render, staticRenderFns } from "./TableActionPlans.vue?vue&type=template&id=ca574386&scoped=true&"
import script from "./TableActionPlans.vue?vue&type=script&setup=true&lang=js&"
export * from "./TableActionPlans.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TableActionPlans.vue?vue&type=style&index=0&id=ca574386&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca574386",
  null
  
)

export default component.exports