<template>
  <div class="container px-0 mb-5">
    <Header />

    <b-card
      class="border-0 radius-top-0 mb-5 min-height"
      no-body
      v-if="objective"
    >
      <b-tabs
        v-model="activeRouteTab"
        pills
        lazy
        align="center"
        nav-class="pill-tabs mx-auto"
        nav-wrapper-class="d-flex"
      >
        <template #tabs-end>
          <div class="position-absolute right-button mr-4">
            <b-button
              v-show="showDraggableButton"
              pill
              variant="outline-secondary"
              size="sm"
              v-b-tooltip.hover
              title="Reordenar listagem"
              class="border-0"
              @click="$bvModal.show('tree-item-draggable-measures')"
            >
              <b-icon icon="gear"></b-icon>
            </b-button>
            <b-button
              v-show="activeRouteTab === 2"
              role="presentation"
              variant="primary"
              class="ml-2 center-button"
              :to="routeToNewActionPlan"
              pill
            >
              Novo plano
            </b-button>
          </div>
        </template>
        <b-tab title="Metas" title-link-class="rounded-pill px-5">
          <list-goals :objective-id="objectiveId" />
        </b-tab>
        <b-tab title="Indicadores" title-link-class="rounded-pill px-5">
          <list-indicators :objective-id="objectiveId" />
        </b-tab>
        <b-tab
          title="Plano de ações"
          title-link-class="rounded-pill px-5"
          class="px-3"
        >
          <table-action-plans
            v-if="activeRouteTab === 2"
            class="my-4"
            @add-action="toPageNewAction"
          />
        </b-tab>
      </b-tabs>
    </b-card>

    <modal-draggable
      title="Ordernar Measures"
      :items="objective.measures"
      id="tree-item-draggable-measures"
      @order="orderMeasuresById($event, objective.id)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "../components/Header";
import ListGoals from "@/modules/objectives/components/ListGoals.vue";
import ListIndicators from "@/modules/objectives/components/ListIndicators.vue";
import TableActionPlans from "@/modules/action_plans/components/TableActionPlans.vue";
import ModalDraggable from "@/components/modal/ModalDraggable";
import { LocalStorage } from "@/helpers/functions/localStorage";

export default {
  components: {
    Header,
    TableActionPlans,
    ModalDraggable,
    ListGoals,
    ListIndicators,
  },
  data: () => ({
    mainProps: {
      blankColor: "#777",
      width: 140,
      height: 140,
    },
    activeTab: LocalStorage("@pam/active-tab-objective").getItem || 0,
  }),
  computed: {
    ...mapState("objectives", ["objective", "ods"]),
    objective_ods() {
      const listOds = this.objective.ods;
      return this.ods.filter((ods) => listOds.indexOf(ods.id) >= 0);
    },
    objectiveId() {
      return this.$route.params.id;
    },
    showDraggableButton() {
      return (
        this.activeRouteTab === 0 &&
        this.objective.measures &&
        this.objective.measures.length > 1
      );
    },
    routeToNewActionPlan() {
      return {
        name: "create_action_plan",
        query: { objectiveId: this.objectiveId },
      };
    },
    activeRouteTab: {
      get() {
        return this.activeTab;
      },
      set(value) {
        this.activeTab = value;
        LocalStorage("@pam/active-tab-objective").store(value);
      },
    },
  },
  methods: {
    ...mapActions("objectives", [
      "ActionShow",
      "ActionGetOds",
      "ActionNew",
      "ActionOrderMeasuresById",
      "ActionGetMeasures",
    ]),
    toPageNewAction() {
      const page = this.routeToNewActionPlan;
      this.$router.push(page);
    },
    async orderMeasuresById($event, id) {
      try {
        const payload = {
          id,
          measures: $event,
        };

        await this.ActionOrderMeasuresById(payload);
        this.$notifications.addNotification({
          message: "Ordem alterada com sucesso",
          type: "success",
        });
      } catch (error) {
        let error_message = "Erro ao salvar";
        this.$notifications.addNotification({
          message: error_message,
          icon: "exclamation-triangle",
          type: "danger",
        });
      }
    },
    async loadData() {
      const parameters = { id: this.$route.params.id };
      await this.ActionNew();
      await Promise.all([this.ActionGetOds(), this.ActionShow(parameters)]);
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .text-order {
  color: #000;
  font-weight: normal;
  cursor: pointer;
  display: block;
  max-width: 60px;
  margin-left: auto;
  border: 1px solid transparent;
}

::v-deep .text-order:hover {
  color: #335080;
  font-weight: normal;
  cursor: pointer;
  border: 1px solid #335080;
}

::v-deep .rounded-pill {
  border-radius: 20px !important;
}

::v-deep .pill-tabs {
  border: 1px solid #d4e0ed;
  border-radius: 30px;
}

.min-height {
  min-height: 300px;
}
::v-deep .min-height-modal {
  min-height: 500px !important;
}

.right-button {
  right: 0;
  top: 2px;
}
</style>
