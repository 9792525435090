const types = {
  date: "__p_date|",
  regx: "__p_expr|",
  bool: "__p_bool|",
  strn: "__p_strn|",
  numb: "__p_numb|",
  objt: "__p_objt|"
};

/**
 * encode items
 */
function encode(val) {
  if (Object.prototype.toString.call(val) === "[object Date]") {
    return types.date + val;
  }

  if (Object.prototype.toString.call(val) === "[object RegExp]") {
    return types.regx + val;
  }

  if (typeof val === "boolean") {
    return types.bool + (val ? "1" : "0");
  }

  if (typeof val === "string") {
    return types.strn + val;
  }

  if (typeof val === "number") {
    return types.numb + val;
  }

  if (typeof val === "object" && (Array.isArray(val) || !Array.isArray(val))) {
    return types.objt + JSON.stringify(val);
  }

  return val;
}

/**
 * decode items
 * @param {string} val
 */
function decode(val) {
  const hasTypeCheck = val.length > 9;
  if (!hasTypeCheck) {
    return val;
  }

  const type = val.substring(0, 9);
  const source = val.substring(9);

  switch (type) {
    case types.date:
      return new Date(source);

    case types.regx:
      return new RegExp(source);

    case types.numb:
      return Number(source);

    case types.bool:
      return Boolean(source === "1");

    case types.strn:
      return "" + source;

    case types.objt:
      return JSON.parse(source);

    default:
      return source;
  }
}
/**
 * Wrapper for localStorage with increased type support
 *
 * @export
 * @param {string} key
 * @return {*}
 */
export function LocalStorage(key) {
  const $localStorage = window.localStorage || self.localStorage;

  return {
    /**
     * save item
     * @param {unknown} data
     */
    store(data) {
      $localStorage.setItem(key, encode(data));
    },
    /**
     * return decoded item
     */
    get getItem() {
      const item = $localStorage.getItem(key);
      return item ? decode(item) : null;
    },
    get has() {
      return $localStorage.getItem(key) !== null;
    },
    delete() {
      $localStorage.removeItem(key);
    },
    clear() {
      $localStorage.clear();
    }
  };
}
