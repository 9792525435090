import { useRoot } from "@/composables/useRoot";

/**
 * Get notifications plugin
 * @returns {{
 *    addNotification: ({ message, type }: { message: string, type: 'success' | 'info' | 'danger' }) => void
 * }}
 */
export function useNotification() {
  const $root = useRoot();
  return $root.$notifications
}
